<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Analysis</title>
    <path
      d="M62.7097 6.96774V32.5161H72V6.96774H62.7097ZM69.6774 30.1935H65.0323V9.29032H69.6774V30.1935Z"
      fill="currentColor"
    />
    <path
      d="M51.0968 18.7113C50.3564 18.0435 49.5726 17.4194 48.7742 16.8532V16.2581H47.9032C43.3742 13.2677 38.0323 11.6129 32.5161 11.6129H31.3548V40.6452H60.3871V39.4839C60.3871 37.1177 60.0677 34.7952 59.4871 32.5161H60.3871V11.6129H51.0968V18.7113ZM33.6774 38.3226V13.9645C36.9581 14.1097 40.1661 14.9081 43.1129 16.2581H39.4839V32.5161H48.7742V19.8145C49.5871 20.4823 50.371 21.1935 51.0968 21.9774V32.5161H57.0774C57.6145 34.4032 57.9484 36.3484 58.0355 38.3226H33.6774ZM46.4516 18.5806V30.1935H41.8064V18.5806H46.4516ZM53.4194 13.9355H58.0645V30.1935H53.4194V13.9355Z"
      fill="currentColor"
    />
    <path
      d="M18.9223 35.1766L9.04795 45.0509L10.6902 46.6932L20.5646 36.8189L18.9223 35.1766Z"
      fill="currentColor"
    />
    <path
      d="M13.9355 36C13.9355 34.0839 12.3677 32.5161 10.4516 32.5161C8.53548 32.5161 6.96774 34.0839 6.96774 36C6.96774 37.9161 8.53548 39.4839 10.4516 39.4839C12.3677 39.4839 13.9355 37.9161 13.9355 36ZM9.29032 36C9.29032 35.3613 9.8129 34.8387 10.4516 34.8387C11.0903 34.8387 11.6129 35.3613 11.6129 36C11.6129 36.6387 11.0903 37.1613 10.4516 37.1613C9.8129 37.1613 9.29032 36.6387 9.29032 36Z"
      fill="currentColor"
    />
    <path
      d="M19.7419 41.8064C17.8258 41.8064 16.2581 43.3742 16.2581 45.2903C16.2581 47.2065 17.8258 48.7742 19.7419 48.7742C21.6581 48.7742 23.2258 47.2065 23.2258 45.2903C23.2258 43.3742 21.6581 41.8064 19.7419 41.8064ZM19.7419 46.4516C19.1032 46.4516 18.5806 45.929 18.5806 45.2903C18.5806 44.6516 19.1032 44.129 19.7419 44.129C20.3806 44.129 20.9032 44.6516 20.9032 45.2903C20.9032 45.929 20.3806 46.4516 19.7419 46.4516Z"
      fill="currentColor"
    />
    <path
      d="M50.9371 59.7339C54.0726 55.1177 55.7419 49.7468 55.7419 44.129V42.9677H29.0323V16.2581H27.871C26.7242 16.2581 25.5629 16.3597 24.3871 16.5048V0H0V16.2581H14.6177L16.8823 18.5226C6.72097 22.8919 0 32.9516 0 44.129C0 59.5016 12.4984 72 27.871 72C34.6064 72 41.0226 69.5613 46.1032 65.1629L49.7032 68.7629C50.4145 69.4742 51.3435 69.8226 52.2726 69.8226C53.2016 69.8226 54.1306 69.4742 54.8419 68.7629C56.25 67.3548 56.25 65.0468 54.8419 63.6387L50.9371 59.7339ZM15.5758 13.9355H2.32258V2.32258H22.0645V20.4242L15.5758 13.9355ZM27.871 69.6774C13.7903 69.6774 2.32258 58.2097 2.32258 44.129C2.32258 33.5758 8.86936 24.0968 18.6677 20.3081L24.3871 26.0274V18.8274C25.171 18.7258 25.9403 18.6387 26.7097 18.5952V45.2903H53.3903C53.1871 49.8774 51.7645 54.2468 49.2532 58.0645L47.3952 56.2065C47.5258 55.6839 47.5984 55.1323 47.5984 54.5661C47.5984 50.7194 44.4774 47.5984 40.6306 47.5984C36.7839 47.5984 33.6629 50.7194 33.6629 54.5661C33.6629 58.4129 36.7839 61.5339 40.6306 61.5339C41.1968 61.5339 41.7484 61.4613 42.271 61.3306L44.4484 63.5081C39.8323 67.4855 33.9968 69.6774 27.871 69.6774ZM45.2903 54.5806C45.2903 57.1355 43.2 59.2258 40.6452 59.2258C38.0903 59.2258 36 57.1355 36 54.5806C36 52.0258 38.0903 49.9355 40.6452 49.9355C43.2 49.9355 45.2903 52.0258 45.2903 54.5806ZM53.1726 67.1081C52.6645 67.6161 51.8371 67.6161 51.329 67.1081L44.5645 60.3435C45.2903 59.85 45.9145 59.2258 46.4081 58.5L53.1871 65.279C53.6806 65.7871 53.6806 66.6 53.1726 67.1081Z"
      fill="currentColor"
    />
    <path
      d="M19.7419 4.64516H4.64516V6.96774H19.7419V4.64516Z"
      fill="currentColor"
    />
    <path
      d="M19.7419 9.29032H4.64516V11.6129H19.7419V9.29032Z"
      fill="currentColor"
    />
    <path
      d="M41.8064 53.4194H39.4839V55.7419H41.8064V53.4194Z"
      fill="currentColor"
    />
    <path
      d="M31.3548 53.4194H29.0323V55.7419H31.3548V53.4194Z"
      fill="currentColor"
    />
    <path
      d="M26.7097 53.4194H24.3871V55.7419H26.7097V53.4194Z"
      fill="currentColor"
    />
    <path
      d="M22.0645 53.4194H19.7419V55.7419H22.0645V53.4194Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'ic-analysis',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
